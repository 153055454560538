<template>
  <div>
    <div class="level-item">
      <h1 class="title is-1 page-title">CREATE ACCOUNT</h1>
    </div>

    <div v-if="!emailSent">
      <div class="box b-shdw-3">
        <!-- Email Address input -->
        <div class="form-input">
          <span class="title is-6">Email Address:</span>
          <input
            type="email"
            class="input is-primary"
            v-model="email"
            @change="checkEmail()"
          />
          <span style="color: red" v-html="emailValiationMessage"></span>
        </div>

        <!-- Email Address input -->
        <div class="form-input">
          <span class="title is-6">Password:</span>
          <input
            type="password"
            class="input is-primary"
            v-model="password"
            @input="checkPasswordStrength()"
          />
          <br /><br />
          <b-progress
            :value="passwordStength"
            size="is-info is-medium"
            :max="4"
            show-value
          >
            Password Strength
          </b-progress>

          <span style="color: red" v-html="passwordValiationMessage"></span>
        </div>

        <!-- Password Confirm input -->
        <div class="form-input">
          <span class="title is-6">Please enter your password again:</span>
          <input
            type="password"
            class="input is-primary"
            v-model="passwordConfirmation"
            @input="checkConfirmPassword()"
          />
          <span style="color: red" v-html="passwordConfirmationMessage"></span>
        </div>

        <!-- Title input -->
        <div class="form-input">
          <span class="title is-6">Title:</span>
          <!-- <input type="text" class="input is-primary" v-model="title" /> -->
          <b-select v-model="title" expanded required>
            <option v-for="t in titles" :key="t.id" :value="t">{{t}}</option>
          </b-select>
        </div>

        <!-- Forename input -->
        <div class="form-input">
          <span class="title is-6">Forename:</span>
          <input type="text" class="input is-primary" v-model="forename" />
        </div>

        <!-- Surname input -->
        <div class="form-input">
          <span class="title is-6">Surname:</span>
          <input type="text" class="input is-primary" v-model="surname" />
        </div>

        <!-- DOB input -->
        <div class="form-input">
          <span class="title is-6">Date of Birth:</span>
          <input type="date" class="input is-primary" v-model="dob" />
        </div>

        <!-- Create account button -->
        <button class="button is-primary is-fullwidth" @click="createAccount">
          Create Account
        </button>
      </div>
    </div>

    <div class="box b-shdw-3" v-else>
      <div class="notification is-info is-light">
        <button class="delete"></button>
        Thank you for requesting access to your Hub area. You will now recieve an email with further instructions.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreateAccount',
  data() {
    return {
      email: '',
      password: '',
      passwordConfirmation: '',
      isPasswordValid: false,
      passwordStength: 0,
      passwordStrengthMessage: '',

      emailValiationMessage: '',
      passwordValiationMessage: '',
      passwordConfirmationMessage: '',

      title: '',
      forename: '',
      surname: '',
      dob: '',

      titles: ['Mrs', 'Mr', 'Miss', 'Ms', 'Dr', 'Rev', 'Prof', 'Father', 'Sir', 'Lady', 'Dame', 'Judge', 'Lord', 'Rt.Hon.', 'Master'],

      emailSent: false,
      pl: 'bradley.hayes@rushinsurance.co.uk',
    };
  },
  methods: {
    // Call API to check if account exists
    checkEmail() {
      this.$http
        .post('customer/check_email', {
          email: this.email,
        })
        .then((result) => {
          this.emailValiationMessage =
            result.data === 'true'
              ? 'Account with this email already exists'
              : '';
        })
        .catch((err) => console.log(err));
    },

    // Do some checks on the password
    checkPasswordStrength() {
      // Strength checks
      this.passwordStength = 0;
      if (this.password.match(/[a-z]+/)) {
        this.passwordStength += 1;
      }
      if (this.password.match(/[A-Z]+/)) {
        this.passwordStength += 1;
      }
      if (this.password.match(/[0-9]+/)) {
        this.passwordStength += 1;
      }
      if (this.password.match(/[$@#&!]+/)) {
        this.passwordStength += 1;
      }

      // Validation
      if (this.password.length < 6) {
        this.passwordValiationMessage =
          'Minimum number of password characters is 6.';
        this.passwordStength -= 1;
        this.isPasswordValid = false;
      } else if (this.passwordStength <= 1) {
        this.passwordValiationMessage =
          'Password needs to includes a mixure of numbers, uppercase and lowercase characters.';
        this.isPasswordValid = false;
      } else {
        this.passwordValiationMessage = '';
        this.isPasswordValid = true;
      }
    },

    // Check password confirmation
    checkConfirmPassword() {
      if (this.passwordConfirmation !== this.password) {
        this.passwordConfirmationMessage = 'This needs to match your password.';
      } else {
        this.passwordConfirmationMessage = '';
      }
    },

    // Create account
    createAccount() {
      // Validate input
      if (
        this.isPasswordValid &&
        this.email !== '' &&
        this.password !== '' &&
        this.passwordConfirmation !== '' &&
        this.passwordConfirmationMessage === '',
        this.title !== '',
        this.forename !== '',
        this.surname !== ''
      ) {
        this.emailSent = true;

        // API create account call
        this.$http
          .post('customer/create_account_manual', {
            email: this.email,
            password: this.password,
            title: this.title,
            forename: this.forename,
            surname: this.surname,
            dob: this.dob
          })
          .then((result) => {
            this.result = result.data;
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.level-item {
  padding: 20px 0 40px 0;
  text-align: center;
}
.form-input {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
